import React from "react"
import { withTheme } from "styled-components"

const Logo = ({ theme: { blue, green }, ...rest }) => (
  <svg {...rest} viewBox="0 0 466 628">
    <g transform="translate(-71.2691 -99.3898)">
      <path
        d="m449.445 366.796c0-65.121-52.871-117.992-117.992-117.992h-62.476c-65.122 0-117.992 52.871-117.992 117.992v20.101c0 65.122 52.87 117.992 117.992 117.992h62.476c65.121 0 117.992-52.87 117.992-117.992z"
        fill={blue}
        stroke={green}
        strokeWidth=".01"
      />
      <path
        d="m467.502 483.445c0-72.386-58.768-131.154-131.153-131.154h-70.879c-72.386 0-131.153 58.768-131.153 131.154v112.542c0 72.385 58.767 131.153 131.153 131.153h70.879c72.385 0 131.153-58.768 131.153-131.153z"
        fill={blue}
        stroke={green}
        strokeWidth=".01"
      />
      <path
        d="m478.766 457.461c-34.546-85.014-59.355-36.987-55.957 46.702 3.308 81.462 34.24 197.576 44.116 195.444 5.888-1.27 16.521-79.76 16.521-178.861 0-35.191-2.161-45.137-4.68-63.285z"
        fill={green}
      />
    </g>
    <path
      d="m527.808-8.895c0-10.523-5.799-19.066-12.942-19.066h-201.279c-7.143 0-12.942 8.543-12.942 19.066v189.032c0 10.523 5.799 19.066 12.942 19.066h201.279c7.143 0 12.942-8.543 12.942-19.066z"
      fill={blue}
      transform="matrix(.931209 .364487 -.931209 .364487 -71.2691 -99.3898)"
    />
    <path
      d="m202.143 188.263c66.115-35.481 135.434-33.92 207.552 0l-.225 87.55c-71.659-37.083-140.168-33.182-206.427 0z"
      fill={green}
      transform="translate(-71.2691 -99.3898)"
    />
    <path
      d="m335.072 423.415c-4.875-15.502-15.187-26.231-27.155-26.231-12.002 0-22.338 10.791-27.197 26.367l27.114 30.413z"
      fill={green}
      fillRule="nonzero"
      transform="translate(-71.2691 -99.3898)"
    />
    <path
      d="m281.023 681.41c-9.726 16.068-58.182-25.296-79.603-47.585-34.381-35.775-65.977-97.925-71.239-130.697-1.691-23.057 2.831-73.633 25.115-86.859 39.275-21.485 130.557 252.194 125.727 265.141z"
      fill={green}
      transform="translate(-71.2691 -99.3898)"
    />
    <g transform="translate(-96.4815 84.3008)">
      <circle cx="415.776" cy="148.921" fill="white" r="87.843" />
      <path
        d="m401.814 239.147c-19.325-3.644-35.714-12.225-49.287-25.806-12.822-12.828-20.983-28.001-24.9-46.289-2.19-10.224-2.171-26.173.044-36.624 3.723-17.569 12.94-33.203 24.921-46.087 6.762-7.271 26.031-23.715 65.76-29.042 0 0 15.9-13.628 24.924-15.368 16.393-5.727 24.002-7.369 26.178-6.954 2.177.414-1.078 2.884-6.415 5.381l-28.357 15.824 15.962 3.895c19.991-9.188 26.921-19.358 74.359-37.543 9.88-3.582 4.62 1.991-9.405 10.491-11.674 7.075-31.006 16.479-38.057 21.522-6.608 4.727-15.744 13.003-15.744 13.003 3.805 5.859 3.347 3.384 6.507 3.158 27.466-3.643 53.42-16.477 77.326-38.235 5.435-4.947 8.268-6.417 12.372-6.417 6.301 0 5.627 3.995-1.895 11.24-15.474 14.902-36.464 28.986-55.47 37.22-7.581 3.284-19.784 7.538-21.624 7.538-.55 0-1 .093-1 .208s1.883 2.144 4.184 4.511c19.534 20.086 29.223 48.866 25.539 75.86-2.873 21.058-11.46 38.593-26.036 53.169-11.683 11.683-26.12 19.754-43.027 24.053-9.157 2.329-27.881 2.985-36.859 1.292zm27.955-18.837c22.859-3.303 44.775-20.048 54.297-41.486 15.863-35.715.891-77.305-34.021-94.504-28.701-14.139-61.801-8.466-84.382 14.463-9.731 9.881-16.228 21.903-19.183 35.497-1.591 7.322-1.628 22.11-.073 29.128 4.989 22.508 18.85 40.34 39.103 50.305 14.328 7.049 27.52 9.016 44.258 6.597z"
        fill="#003189"
        stroke="white"
        strokeWidth=".01"
      />
      <ellipse cx="418.84" cy="144.796" rx="27.003" ry="26.875" />
      <ellipse cx="396.526" cy="129.179" fill="white" rx="10.278" ry="9.924" />
    </g>
    <g transform="matrix(-1 0 0 1 562.298 84.3008)">
      <circle cx="415.776" cy="148.921" fill="white" r="87.843" />
      <path
        d="m401.814 239.147c-19.325-3.644-35.714-12.225-49.287-25.806-12.822-12.828-20.983-28.001-24.9-46.289-2.19-10.224-2.171-26.173.044-36.624 3.723-17.569 12.94-33.203 24.921-46.087 6.762-7.271 26.031-23.715 65.76-29.042 0 0 15.9-13.628 24.924-15.368 16.393-5.727 24.002-7.369 26.178-6.954 2.177.414-1.078 2.884-6.415 5.381l-28.357 15.824 15.962 3.895c19.991-9.188 26.921-19.358 74.359-37.543 9.88-3.582 4.62 1.991-9.405 10.491-11.674 7.075-31.006 16.479-38.057 21.522-6.608 4.727-15.744 13.003-15.744 13.003 3.805 5.859 3.347 3.384 6.507 3.158 27.466-3.643 53.42-16.477 77.326-38.235 5.435-4.947 8.268-6.417 12.372-6.417 6.301 0 5.627 3.995-1.895 11.24-15.474 14.902-36.464 28.986-55.47 37.22-7.581 3.284-19.784 7.538-21.624 7.538-.55 0-1 .093-1 .208s1.883 2.144 4.184 4.511c19.534 20.086 29.223 48.866 25.539 75.86-2.873 21.058-11.46 38.593-26.036 53.169-11.683 11.683-26.12 19.754-43.027 24.053-9.157 2.329-27.881 2.985-36.859 1.292zm27.955-18.837c22.859-3.303 44.775-20.048 54.297-41.486 15.863-35.715.891-77.305-34.021-94.504-28.701-14.139-61.801-8.466-84.382 14.463-9.731 9.881-16.228 21.903-19.183 35.497-1.591 7.322-1.628 22.11-.073 29.128 4.989 22.508 18.85 40.34 39.103 50.305 14.328 7.049 27.52 9.016 44.258 6.597z"
        fill="#003189"
        stroke="white"
        strokeWidth=".01"
      />
      <ellipse cx="418.84" cy="144.796" rx="27.003" ry="26.875" />
      <ellipse cx="438.525" cy="129.179" fill="white" rx="10.278" ry="9.924" />
    </g>
  </svg>
)

export default withTheme(Logo)
